import billing_icon from 'assets/images/billing_staff.svg';
const billing = {
    id: 'billing',
    type: 'group',
    children: [
        {
            id: 'billing',
            title: 'Billing',
            type: 'item',
            icon: billing_icon,
            url: 'staff/billing'
        }
    ]
};

export default billing;
