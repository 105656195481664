// constant
import Dashboard_icon from 'assets/images/default.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'staff_dashboard',
    type: 'group',
    children: [
        {
            id: 'staff_default',
            title: 'Home',
            type: 'item',
            url: '/staff/dashboard',
            icon: Dashboard_icon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
