import Billing_icon from 'assets/images/Group_3214.svg';

const billing = {
    id: 'billing_dashboard',
    type: 'group',
    children: [
        {
            id: 'billing_dashboard',
            title: 'Billing',
            type: 'collapse',
            icon: Billing_icon,

            children: [
                {
                    id: 'client_billing_dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: 'client/billing/dashboard'
                },
                {
                    id: 'pending',
                    title: 'Pending',
                    type: 'item',
                    url: '/client/billing/pending'
                },
                {
                    id: 'bill_completed',
                    title: 'Completed',
                    type: 'item',
                    url: '/client/billing/completed'
                }
            ]
        }
    ]
};

export default billing;
