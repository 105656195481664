import company_information from 'assets/images/company_information.svg';

const companyInformation = {
    id: 'companyinformation',
    type: 'group',
    children: [
        {
            id: 'company_information',
            title: 'Company Information',
            type: 'collapse',
            icon: company_information,

            children: [
                {
                    id: 'companyinformation_dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: 'client/company-information/dashboard'
                },
                {
                    id: 'officers',
                    title: 'Officers',
                    type: 'item',
                    url: '/client/company-information/officers'
                },
                {
                    id: 'shareholders',
                    title: 'Shareholders / Members',
                    type: 'item',
                    url: '/client/company-information/shareholder'
                },
                {
                    id: 'controllers',
                    title: 'Controllers',
                    type: 'item',
                    url: '/client/company-information/controller'
                }
                // {
                //     id: 'dpo',
                //     title: 'Data Protection Officer',
                //     type: 'item',
                //     url: '/client/dashboard'
                // }
            ]
        }
    ]
};

export default companyInformation;
