// constant
import documentSvg from 'assets/images/document.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const registerType = {
    id: 'admin_register_type',
    type: 'group',
    children: [
        {
            id: 'admin_register_type',
            title: 'Register Type',
            type: 'item',
            url: '/admin/register_type',
            icon: documentSvg,
            breadcrumbs: false
        }
    ]
};

export default registerType;
