import company_information from 'assets/images/company_information.svg';

const companyTask = {
    id: 'staff_companytask',
    type: 'group',
    children: [
        {
            id: 'staff_company_task',
            title: 'Company Task',
            type: 'item',
            icon: company_information,
            url: 'staff/company_tasks'
        }
    ]
};

export default companyTask;
