import adhocTaskSVG from 'assets/images/adhocTask.svg';

const adhocTask = {
    id: 'staff_adhocTask',
    type: 'group',
    children: [
        {
            id: 'staff_adhocTask',
            title: 'Onboarding Task',
            type: 'item',
            icon: adhocTaskSVG,
            url: 'staff/adhocTask'
        }
    ]
};

export default adhocTask;
