// constant
import workflow_icon from 'assets/images/workflow.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const workflow = {
    id: 'admin_workflow',
    type: 'group',
    children: [
        {
            id: 'admin_workflow',
            title: 'Workflow',
            type: 'item',
            url: '/admin/workflow',
            icon: workflow_icon,
            breadcrumbs: false
        }
    ]
};

export default workflow;
