import messages_icon from 'assets/images/messages.svg';

const messages = {
    id: 'messages',
    type: 'group',
    children: [
        {
            id: 'messages',
            title: 'Messages',
            type: 'item',
            icon: messages_icon,
            url: '/staff/message'
        }
    ]
};

export default messages;
