import { Route, Routes, Navigate } from 'react-router-dom';

// routes
import { StaffProtectedRoute, ClientProtectedRoute, AdminProtectedRoute } from './ProtectedRoutes';
import { lazy } from 'react';
// project imports
import Loadable from 'ui-component/Loadable';
import ClientLayout from 'layout/MainLayout';
import StaffLayout from 'layout/StaffLayout';
import AdminLayout from 'layout/AdminLayout';

import { getStorageUserType } from "utils/common";

const AuthLogin3 = Loadable(lazy(() => import('views/authentication/Login')));
const AdminLogin = Loadable(lazy(() => import('views/admin_panel/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('views/authentication/Forgotpassword')));
const FPasswordMail = Loadable(lazy(() => import('views/authentication/Fpassmail')));
const ResetPassword = Loadable(lazy(() => import('views/authentication/ResetPassword')));
const ComplianceForm = Loadable(lazy(() => import('views/shared/complianceForm')));

const AdminUser = Loadable(lazy(() => import('views/admin_panel/user')));
const AdminTemplate = Loadable(lazy(() => import('views/admin_panel/template')));
const AdminWorkflow = Loadable(lazy(() => import('views/admin_panel/workflow')));
const EditWorkflow = Loadable(lazy(() => import('views/admin_panel/workflow/editWorkFlow')));
const ViewWorkflow = Loadable(lazy(() => import('views/admin_panel/workflow/workFlowDetail')));
const AdminAddUser = Loadable(lazy(() => import('views/admin_panel/user/AddUser')));
const AdminUpdateUser = Loadable(lazy(() => import('views/admin_panel/user/updateUser')));
const AdminAddTemplate = Loadable(lazy(() => import('views/admin_panel/template/addTemplate')));
const AdminEditTemplate = Loadable(lazy(() => import('views/admin_panel/template/editTemplate')));
const AdminAddWorkFlow = Loadable(lazy(() => import('views/admin_panel/workflow/addWorkFlow')));
const AdminRegisterType = Loadable(lazy(() => import('views/admin_panel/registerType')));
const AdminAddRegisterType = Loadable(lazy(() => import('views/admin_panel/registerType/addRegisterType')));
const AdminEditRegisterType = Loadable(lazy(() => import('views/admin_panel/registerType/editRegisterType')));
const ClientDashboardDefault = Loadable(lazy(() => import('views/client_panel/dashboard')));
const AdminLogsDashboard = Loadable(lazy(() => import('views/admin_panel/logs/dashboard')));
const AdminSigningLog = Loadable(lazy(() => import('views/admin_panel/logs/signing')));
const AdminSetting = Loadable(lazy(() => import('views/admin_panel/setting')));

//=================|| Staff Imports ||===============//

const StaffAdhocTask = Loadable(lazy(() => import('views/staff_panel/adhoctask')));
const StaffAdhocTaskDetail = Loadable(lazy(() => import('views/staff_panel/adhoctask/Detail')));
const StaffDashboardDefault = Loadable(lazy(() => import('views/staff_panel/dashboard')));
const CompanyTaskAddCompany = Loadable(lazy(() => import('views/staff_panel/entities/company')));
const CompanyTaskEditCompany = Loadable(lazy(() => import('views/staff_panel/entities/company/editCompany')));
const CompanyTaskOtherCompany = Loadable(lazy(() => import('views/staff_panel/entities/othercompany')));
const CompanyTaskAddPeople = Loadable(lazy(() => import('views/staff_panel/entities/people')));
const Entities = Loadable(lazy(() => import('views/staff_panel/entities')));
const StaffWorkflow = Loadable(lazy(() => import('views/staff_panel/workflow')));
const CompanyTasks = Loadable(lazy(() => import('views/staff_panel/companytasks')));
const CompanyDetails = Loadable(lazy(() => import('views/staff_panel/companytasks/CompanyDetails')));
const NewCompanyTask = Loadable(lazy(() => import('views/staff_panel/companytasks/NewCompanyTask')));
const Billing = Loadable(lazy(() => import('views/staff_panel/billing')));
const BillingDetail = Loadable(lazy(() => import('views/staff_panel/billing/BillingDetail')));
const ClientBillingDetail = Loadable(lazy(() => import('views/client_panel/billing/BillingDetail')));
const AddBilling = Loadable(lazy(() => import('views/staff_panel/billing/AddBilling')));
const Requests = Loadable(lazy(() => import('views/staff_panel/requests')));
const StaffClientRequestDetail = Loadable(lazy(() => import('views/staff_panel/requests/detail')));
const Message = Loadable(lazy(() => import('views/staff_panel/message')));
const EditOtherCompany = Loadable(lazy(() => import('views/staff_panel/entities/othercompany/editOtherCompany')));
const EditPeople = Loadable(lazy(() => import('views/staff_panel/entities/people/editPeople')));
const StaffViewWorkflow = Loadable(lazy(() => import('views/staff_panel/workflow/workFlowDetail')));
const ComapnyTaskDetail = Loadable(lazy(() => import('views/staff_panel/companytasks/ComapnyTaskDetail')));
const ComapnyOfficerDetail = Loadable(lazy(() => import('views/staff_panel/entities/company/officerdetal')));
const ShareholderDetail = Loadable(lazy(() => import('views/staff_panel/entities/company/shareholder')));
const ComplianceList = Loadable(lazy(() => import('views/staff_panel/compliance')));
const ComplianceDetail = Loadable(lazy(() => import('views/staff_panel/compliance/Detail')));
const TodoTask = Loadable(lazy(() => import('views/staff_panel/todotask')));
const CreateTodoTask = Loadable(lazy(() => import('views/staff_panel/todotask/Create')));
const EditTodoTask = Loadable(lazy(() => import('views/staff_panel/todotask/Edit')));
const ViewTodoTask = Loadable(lazy(() => import('views/staff_panel/todotask/Detail')));

const AdhocTaskRequest = Loadable(lazy(() => import('views/client_panel/adhoc')));
//=================|| Companyinformation Imports ||===============//
const CompanyInformationDasboard = Loadable(lazy(() => import('views/client_panel/company_information/dashboard')));
const CompanyInformationSecurity = Loadable(lazy(() => import('views/client_panel/company_information/security')));
const CompanyInformationOfficer = Loadable(lazy(() => import('views/client_panel/company_information/officers')));
const CompanyInformationShareholders = Loadable(lazy(() => import('views/client_panel/company_information/shareholders')));
const CompanyInformationControllers = Loadable(lazy(() => import('views/client_panel/company_information/controllers')));

//=================|| Billing Imports ||===============//

const ClientBillingDashboard = Loadable(lazy(() => import('views/client_panel/billing/dashboard')));
const ClientBillingPending = Loadable(lazy(() => import('views/client_panel/billing/pending')));
const ClientBillingCompleted = Loadable(lazy(() => import('views/client_panel/billing/completed')));

//=================|| Requests Imports ||===============//

const ClientRequestsDashboard = Loadable(lazy(() => import('views/client_panel/requests/dashboard')));
const ClientRequestNew = Loadable(lazy(() => import('views/client_panel/requests/new')));
const ClientRequestEdit = Loadable(lazy(() => import('views/client_panel/requests/edit')));
const ClientRequestDetail = Loadable(lazy(() => import('views/client_panel/requests/detail')));

//=================|| Logs Imports ||===============//
const ClientDocumentDashboard = Loadable(lazy(() => import('views/client_panel/document/dashboard')));
const ClientDocumentExploror = Loadable(lazy(() => import('views/client_panel/document/documentexploror')));

const ClientMessage = Loadable(lazy(() => import('views/client_panel/message')));
const ChangePassword = Loadable(lazy(() => import('views/shared/changePassword')));
const ChangedProfile = Loadable(lazy(() => import('views/shared/changeProfile')));

// ==============================|| ROUTING RENDER ||==============================//

const Logout = () => {
    const authUserType = getStorageUserType();

    localStorage.setItem(
        'auth_user',
        JSON.stringify({
            user: ''
        })
    );
    localStorage.setItem(
        'token',
        JSON.stringify({
            token: ''
        })
    );
    localStorage.setItem(
        'auth_user_type',
        JSON.stringify({
            type: ''
        })
    );
    localStorage.removeItem("CompanyData");

    console.log(authUserType)
    if (authUserType.type === "Admin") {
        return <Navigate to="/admin/login" />;
    } else {
        return <Navigate to="/login" />;
    }
};

export default function ThemeRoutes() {
    return (
        <Routes>
            <Route exact path="/login" element={<AuthLogin3 />} />
            <Route exact path="/admin/login" element={<AdminLogin />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            <Route exact path="/password-reset/:id/:token" element={<ResetPassword />} />
            <Route exact path="/forgotpassword/email" element={<FPasswordMail />} />
            <Route exact path="/compliance-form/:shareholderId/:complianceId" element={<ComplianceForm />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/register" element={<AdhocTaskRequest />} />
            <Route exact path="/register/:user_code" element={<AdhocTaskRequest />} />
            <ClientProtectedRoute>
                <Route exact path="/client" element={<ClientLayout />}>
                    <Route path="/dashboard" element={<ClientDashboardDefault />} />
                    {/* //=================|| Companyinformation Imports ||===============// */}
                    <Route path="/company-information/dashboard" element={<CompanyInformationDasboard />} />
                    <Route path="/company-information/security" element={<CompanyInformationSecurity />} />
                    <Route path="/company-information/officers" element={<CompanyInformationOfficer />} />
                    <Route path="/company-information/shareholder" element={<CompanyInformationShareholders />} />
                    <Route path="/company-information/controller" element={<CompanyInformationControllers />} />
                    {/* //================= Billing Routes ===============// */}
                    <Route path="/billing/dashboard" element={<ClientBillingDashboard />} />
                    <Route path="/billing/pending" element={<ClientBillingPending />} />
                    <Route path="/billing/completed" element={<ClientBillingCompleted />} />
                    <Route path="/billing/detail/:id" element={<ClientBillingDetail />} />
                    {/* //================= Requests Routes ===============// */}
                    <Route path="/request" element={<ClientRequestsDashboard />} />
                    <Route path="/request/new" element={<ClientRequestNew />} />
                    <Route path="/request/edit/:id" element={<ClientRequestEdit />} />
                    <Route path="/request/detail/:id" element={<ClientRequestDetail />} />

                    {/* //================= Document Routes ===============// */}
                    <Route path="/document/dashboard" element={<ClientDocumentDashboard />} />
                    <Route path="/document/documentexploror" element={<ClientDocumentExploror />} />

                    <Route path="/message" element={<ClientMessage />} />
                    <Route path="/account/password" element={<ChangePassword />} />
                    <Route path="/account/profile" element={<ChangedProfile />} />
                </Route>
            </ClientProtectedRoute>
            <StaffProtectedRoute>
                <Route exact path="/staff" element={<StaffLayout />}>
                    <Route path="/dashboard" element={<StaffDashboardDefault />} />
                    <Route path="/adhocTask" element={<StaffAdhocTask />} />
                    <Route path="/adhocTask/:taskId" element={<StaffAdhocTaskDetail />} />
                    <Route path="/company_tasks" element={<CompanyTasks />} />
                    <Route path="/ComapnyTaskDetail/:id/:companyid" element={<ComapnyTaskDetail />} />
                    <Route path="/company_details/:id" element={<CompanyDetails />} />
                    <Route path="/New_Task" element={<NewCompanyTask />} />
                    <Route path="/New_Task/draft/:taskid" element={<NewCompanyTask />} />
                    <Route path="/New_Task/:requestid" element={<NewCompanyTask />} />
                    <Route path="/todo_tasks" element={<TodoTask />} />
                    <Route path="/todo_tasks/new" element={<CreateTodoTask />} />
                    <Route path="/todo_tasks/edit/:id" element={<EditTodoTask />} />
                    <Route path="/todo_tasks/view/:id" element={<ViewTodoTask />} />
                    <Route path="/entities" element={<Entities />} />
                    <Route path="/workflow" element={<StaffWorkflow />} />
                    <Route path="/view_workflow/:id" element={<StaffViewWorkflow />} />
                    <Route path="/billing" element={<Billing />} />
                    <Route path="/add_billing" element={<AddBilling />} />
                    <Route path="/add_billing/:companyid/:taskid" element={<AddBilling />} />
                    <Route path="/requests" element={<Requests />} />
                    <Route path="/request/detail/:id" element={<StaffClientRequestDetail />} />
                    <Route path="/message" element={<Message />} />
                    {/* //================= logs Routes ===============// */}
                    <Route path="/log/dashboard" element={<AdminLogsDashboard />} />

                    <Route path="/add_company" element={<CompanyTaskAddCompany />} />
                    <Route path="/edit_company/:id" element={<CompanyTaskEditCompany />} />
                    <Route path="/editother_company/:id" element={<EditOtherCompany />} />
                    <Route path="/edit_people/:id" element={<EditPeople />} />
                    <Route path="/other_company" element={<CompanyTaskOtherCompany />} />
                    <Route path="/add_people" element={<CompanyTaskAddPeople />} />
                    <Route path="/CompanyOfficerDetail/:id" element={<ComapnyOfficerDetail />} />
                    <Route path="/shareholderDetail/:id" element={<ShareholderDetail />} />
                    <Route path="/BillingDetail/:id" element={<AddBilling />} />
                    <Route path="/compliance" element={<ComplianceList />} />
                    <Route path="/compliance/:id" element={<ComplianceDetail />} />
                    <Route path="/account/password" element={<ChangePassword />} />
                    <Route path="/account/profile" element={<ChangedProfile />} />
                </Route>
            </StaffProtectedRoute>
            <AdminProtectedRoute>
                <Route exact path="/admin" element={<AdminLayout />}>
                    <Route path="/template" element={<AdminTemplate />} />
                    <Route path="/user" element={<AdminUser />} />
                    <Route path="/add_user" element={<AdminAddUser />} />
                    <Route path="/update_user/:uId" element={<AdminUpdateUser />} />
                    <Route path="/workflow" element={<AdminWorkflow />} />
                    <Route path="/add_workflow" element={<AdminAddWorkFlow />} />
                    <Route path="/add_template" element={<AdminAddTemplate />} />
                    <Route path="/edit_workflow/:id" element={<EditWorkflow />} />
                    <Route path="/view_workflow/:id" element={<ViewWorkflow />} />
                    <Route path="/edit_template/:id" element={<AdminEditTemplate />} />
                    <Route path="/account/password" element={<ChangePassword />} />
                    <Route path="/account/profile" element={<ChangedProfile />} />
                    {/* //================= logs Routes ===============// */}
                    <Route path="/log/dashboard" element={<AdminLogsDashboard />} />
                    <Route path="/signinglogs" element={<AdminSigningLog />} />

                    <Route path="/register_type" element={<AdminRegisterType />} />
                    <Route path="/register_type/add" element={<AdminAddRegisterType />} />
                    <Route path="/register_type/edit/:id" element={<AdminEditRegisterType />} />

                    <Route path="/setting" element={<AdminSetting />} />
                </Route>
            </AdminProtectedRoute>
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
}
