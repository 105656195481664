import Entity_icon from 'assets/images/entity_staff.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const entities = {
    id: 'staff_entities',
    type: 'group',
    children: [
        {
            id: 'staff_entities',
            title: 'Entities',
            type: 'item',
            url: '/staff/entities',
            icon: Entity_icon,
            breadcrumbs: false
        }
    ]
};

export default entities;
