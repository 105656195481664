import Log_icon from 'assets/images/Group_3215.svg';

const logs = {
    id: 'logs',
    type: 'group',
    children: [
        {
            id: 'logs',
            title: 'Logs',
            type: 'item',
            url: '/staff/log/dashboard',
            icon: Log_icon,

            // children: [
            //     {
            //         id: 'logdashboard',
            //         title: 'Dashboard',
            //         type: 'item',
            //         url: '/staff/log/dashboard'
            //     },
            //     {
            //         id: 'activity',
            //         title: 'Activity',
            //         type: 'item',
            //         url: '/staff/log/activity'
            //     },
            //     {
            //         id: 'System',
            //         title: 'System',
            //         type: 'item',
            //         url: '/staff/log/system'
            //     },
            //     {
            //         id: 'error',
            //         title: 'Errors',
            //         type: 'item',
            //         url: '/staff/log/error'
            //     },
            //     {
            //         id: 'warning',
            //         title: 'Warnings',
            //         type: 'item',
            //         url: '/staff/log/warning'
            //     }
            // ]
        }
    ]
};

export default logs;
