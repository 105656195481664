// constant
import settingSVG from 'assets/images/setting.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const setting = {
    id: 'admin_setting',
    type: 'group',
    children: [
        {
            id: 'admin_setting',
            title: 'Setting',
            type: 'item',
            url: '/admin/setting',
            icon: settingSVG,
            breadcrumbs: false
        }
    ]
};

export default setting;
