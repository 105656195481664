// material-ui
import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@mui/material';
import moment from 'moment';
// assets
import User1 from 'assets/images/users/user-round.svg';
import { checkIfImageExists } from "utils/common";

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = (data) => {
	const theme = useTheme();
    return (
        <List
            sx={{
                width: 400,
                maxWidth: 400,
                maxHeight: 530,
                overflowY: "scroll",
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 400
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 32
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                },
                ".MuiListItemText-multiline p": {
                    paddingRight: "95px"
                },
                "img": {
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "50px"
                }
            }}
        >
            {data.data?.map((e) => (
                <>
                    {' '}
                    <ListItemWrapper style={{backgroundColor: e.isRead ? "inherit" : "#fedada57"}}>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                {
                                    e.profile ? (
                                        <img src={checkIfImageExists(e.profile)} alt="profile-img" />
                                    ) : (
                                        <Avatar alt="John Doe" src={User1} />
                                    )
                                }
                            </ListItemAvatar>
                            <ListItemText primary={e.senderName} secondary={e.title} />
                            <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12}>
                                        <Typography style={{marginBottom: "0px"}} className variant="caption" display="block" gutterBottom>
                                            {moment(e.createdAt).format('DD/MM/YYYY')}
                                        </Typography>
                                        <Typography style={{marginBottom: "0px"}} variant="caption" display="block" gutterBottom>
                                            {moment(e.createdAt).format('h:mm a')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </ListItemWrapper>
                    <Divider />
                </>
            ))}
        </List>
    );
};

export default NotificationList;
