// constant
import Dashboard_icon from 'assets/images/default.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'client_dashboard',
    type: 'group',
    children: [
        {
            id: 'client_default',
            title: 'Dashboard',
            type: 'item',
            url: '/client/dashboard',
            icon: Dashboard_icon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
