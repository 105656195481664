import requests_icon from 'assets/images/requests.svg';

const task_requests = {
    id: 'requests',
    type: 'group',
    children: [
        {
            id: 'requests',
            title: 'Requests',
            type: 'item',
            icon: requests_icon,
            url: '/client/request'
        }
    ]
};

export default task_requests;
