import countryJson from "countrycitystatejson";
import moment from "moment";
import * as XLSX from "xlsx";

import ProfileSVG from "assets/images/Profile.svg";

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
const upperAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

export const countries = countryJson.getCountries().sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});

export const getStates = (code) => {
  if (code)
    return countryJson.getStatesByShort(code);

  return [];
}

export const getCities = (code, state) => {
  if (code && state)
    return countryJson.getCities(code, state);

  return [];
}

export const getCountryInfoByShort = (code) => {
  if (code)
    return countryJson.getCountryInfoByShort(code);

  return {};
}

export const getStorageCompany = () => {
  let data = null;
  const strCompany = localStorage.getItem("CompanyData");
  if (strCompany) {
    data = JSON.parse(strCompany);
  }

  return data;
}
export const formatDate = (value, format) => {
  if (value && value !== "") {
    return moment(value).format(format ? format : "DD-MMMM-YYYY");
  }

  return "-";
}
export const formatDateTime = (value) => {
  if (value && value !== "") {
    return moment(value).format("DD-MMM-YYYY HH:mm:ss");
  }

  return "-";
}

export const formatDateInput = (value) => {
  if (value && value !== "") {
    return moment(value).format("YYYY-MM-DD");
  }

  return "-";
}

export const getDiffDays = (value) => {
  if (value) {
      return moment(value).diff(moment(), "days");
  }

  return 0;
}

export const getStorageUserType = () => {
  let data = null;
  const strAuthType = localStorage.getItem("auth_user_type");
  if (strAuthType) {
    data = JSON.parse(strAuthType);
  }

  return data; 
}
export const getStorageUser = () => {
  let data = null;
  const auth_user = localStorage.getItem("auth_user");
  if (auth_user) {
    data = JSON.parse(auth_user);
  }

  return data?.user; 
}

export const getListMeetings = () => {
  const Meetings = [
    { label: 'New Task', value: 'NewTask' },
    { label: 'Choose Template', value: 'ChooseTemplate' },
    { label: 'Additional Task', value: 'AdditionalTask' },
    { label: 'Reminder', value: 'Reminder' },
    { label: 'Choose Users', value: 'ChooseUsers' },
    { label: 'Uploading', value: 'Uploading' },
    // { label: 'Billing', value: 'Billing' }
  ];
  
  return Meetings;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getNextChar = (char, increase) => {
  if (char === "" && increase === 1) {
    return "a";
  } else {
    return String.fromCharCode((char === "" ? "a" : char).charCodeAt(0) + (char === "" ? increase - 1 : increase));
  }
}

export const getUniqueArr = (arr, field) => {
  if (field) {
    const uniqueArray = arr.filter(function(item, index) {
      return arr.map(x => x[field]).indexOf(item.email) === index;
    });
    return uniqueArray;
  }

  const uniqueArray = arr.filter(function(item, index) {
    return arr.indexOf(item) === index;
  });

  return uniqueArray;
}

export const convertDataURIToBinary = (dataURI) => {
  var BASE64_MARKER = ';base64,'
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  // var raw = window.atob(base64);
  // var rawLength = raw.length;
  // var array = new Uint8Array(new ArrayBuffer(rawLength));

  // for(var i = 0; i < rawLength; i++) {
  //   array[i] = raw.charCodeAt(i);
  // }
  return base64;
}

export const convertPdfURIToBinary = (dataURI) => {
  if (!dataURI) return [];
  
  var raw = window.atob(dataURI);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
  }
  return array;
};

export const convertPdfToByte = (dataURI) => {
  var BASE64_MARKER = ';base64,'
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export const downloadFile = (title, file) => {
  // trigger download
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = title;
  link.href = file;
  link.click();
}

export const openFileNewTab = (base64PDF) => {
  const blob = new Blob([convertPdfToByte(base64PDF)], { type: 'application/pdf' });
  const docUrl = URL.createObjectURL(blob);

  // Open the PDF in a new tab
  window.open(docUrl, '_blank');
}

export const getCompanyChangesDesc = (value, field) => {
	let desc = "";
  if (field === "companyname") {
		desc = `The Company Name be changed to ${value.companyname}`;
	}
	if (field === "registeraddress") {
		desc = `The Company Address be changed to ${value.registeraddress}`;
	}
	if (field === "financialyearend") {
		desc = `The Company Financial Year End be changed to ${formatDate(value.financialyearend)}`;
	}
	if (field === "activitycode") {
		desc = `The new primary activity of the company will be (${value.activitycode}) ${value.activitytitle}`;
	}
	if (field === "activitycode2") {
		desc = `The new secondary activity of the company will be (${value.activitycode2}) ${value.activitytitle2}`;
	}
	if (field === "strikeOff") {
		desc = `Striking off of the Company`;
	}

	return desc;
}

export const getStatusTask = () => {
  return [
    { label: 'Draft', value: 'Draft' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Waiting For Signature', value: 'Waiting For Signature' },
    {
        label: 'Signed',
        value: 'Signed'
    },
    { label: 'Completed/Filed With ACRA', value: 'Completed/Filed With ACRA' },
    {
        label: 'Cancel',
        value: 'Cancel'
    }
  ];
}

export const getStatusTodoTask = () => {
  return [
    { label: 'Pending', value: 'Pending' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
  ];
}

export const getStatusAdhocTask = () => {
  return [
    { label: 'Pending', value: 'Pending' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Cancel', value: 'Cancel' }
  ];
}

export const getTemplateType = () => {
  return [
    { label: 'Task', value: 'task' },
    { label: 'Adhoc Task - Agreeement', value: 'agreement' },
    { label: 'Adhoc Task - Consent to act as director', value: 'consentDirector' },
    { label: 'Adhoc Task - Consent to act as manager (VCR 1)', value: 'consentManagerVcc' },
    { label: 'Adhoc Task - Consent to act as director (VCR 2)', value: 'consentDirectorVcc' },
    { label: 'Adhoc Task - User Guide', value: 'registerUserGuide' }
  ];
}

export const getEsigndocId = (Company) => {
  let requestId = null;
  if (Company?.signProvider === "Hellosign") {
    requestId = Company?.signRes?.signature_request_id;
  }
  if (Company?.signProvider === "Signrequest") {
    requestId = Company?.signRes?.uuid;
  }

  return requestId;
}

export const exportXlsx = (values, colWidth, sheetName, downloadName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(values);

  ws["!cols"] = colWidth;
  
  XLSX.utils.book_append_sheet(wb, ws, sheetName ? sheetName : "Sheet1");
  XLSX.writeFile(wb, `${downloadName ? downloadName : "download"}.xlsx`)
}

export const generateRandomStr = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const checkIfImageExists = (url) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    return url;
  } else {
    return ProfileSVG;
  }
}

export const getPositionOfficers = () => {
  return [
    { label: 'Director', value: 'Director' },
    { label: 'Secretary', value: 'Secretary' },
    { label: 'Managing Director', value: 'Managing Director' },
    { label: 'Auditor', value: 'Auditor' },
    { label: 'Alternate Director', value: 'Alternate Director' },
    { label: 'Chief Executive Officer', value: 'Chief Executive Officer' },
    { label: 'Data Protection Officer', value: 'Data Protection Officer' }
  ]
}

export const getRequestStatus = () => {
  return [
    { label: 'Pending', value: 'Pending' },
    { label: 'In Progress', value: 'In Progress' },
    {
        label: 'Completed',
        value: 'Completed'
	},
	{
        label: 'Cancel',
        value: 'Cancel'
    }
  ];
}

export const getDuplicateList = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) !== index);
}

export const getAlphabetByIndex = (index, isAlpha) => {
  if (isAlpha) {
    return upperAlphabet[index];
  }
  
  return alphabet[index];
}