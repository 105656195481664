import requests_icon from 'assets/images/requests.svg';

const requests = {
    id: 'request',
    type: 'group',
    children: [
        {
            id: 'requests',
            title: 'Requests',
            type: 'item',
            icon: requests_icon,
            url: 'staff/requests'
        }
    ]
};

export default requests;
