import document_icon from 'assets/images/document.svg';

const document = {
    id: 'document',
    type: 'group',
    children: [
        {
            id: 'document',
            title: 'Document',
            type: 'collapse',
            icon: document_icon,

            children: [
                {
                    id: 'documentdashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/client/document/dashboard'
                },
                {
                    id: 'documents_explorer',
                    title: 'Document Explorer',
                    type: 'item',
                    url: '/client/document/documentexploror'
                }
            ]
        }
    ]
};

export default document;
