import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { getclientcompany } from 'api/api';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        let user = localStorage.getItem('auth_user');
        let parseData = JSON.parse(user);
        const storageCompany = localStorage.getItem("CompanyData");
        // let userId = parseData.user._id;
        getclientcompany(parseData)
        .then((res) => {
            setCompanies(res.data.companies ? res.data.companies : []);
            if (storageCompany) {
                const parseCompany = JSON.parse(storageCompany);
                if (res.data.companies?.filter(x => x.uId === parseCompany?.company?.uId).length === 0) {
                    changeCompany(res.data.companies[0]);
                } else {
                    changeCompany(parseCompany?.company);
                }
            } else {
                if (res.data.companies?.length > 0) {
                    changeCompany(res.data.companies[0]);
                }
            }
        });
    }, []);

    const changeCompany = (value, reload) => {
        localStorage.setItem("CompanyData", JSON.stringify({company: value}));
        setSelectedCompany(value);

        if (reload) {
            window.location.reload();
        }
    }

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            <div>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    style={{backgroundColor: "#e3f2fd"}}
                    onClick={(e) => setOpen(e.currentTarget)}
                >
                    {selectedCompany === null ? "Select Company" : selectedCompany.corpName}
                    {
                        open === null ? (
                            <ArrowDropDownIcon />
                        ) : (
                            <ArrowDropUpIcon />
                        )
                    }
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={open}
                    open={open !== null}
                    onClose={() => setOpen(null)}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        companies?.map((company, index) => (
                            <MenuItem key={index} onClick={(e) => {
                                changeCompany(company, true);
                                setOpen(null);
                            }}>{company.corpName}</MenuItem>
                        ))
                    }
                </Menu>
            </div>
            <NotificationSection />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
