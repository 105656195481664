import axios from 'axios';

// function getToken() {
//     if (localStorage.getItem('auth_user')) {
//         const parseData = JSON.parse(localStorage.getItem('auth_user'));
//         return parseData.token;
//     }
//     return '';
// }

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache'
    }
});

export default instance;
