import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const isAuth = () => {
    const parse = JSON.parse(localStorage.getItem('auth_user_type'));
    const data = parse?.type;

    return data;
};

const StaffProtectedRoute = () => {
    const inCheck = isAuth();

    return inCheck === 'Staff' ? <Outlet /> : <Navigate to="/login" />;
};

const ClientProtectedRoute = () => {
    const inCheck = isAuth();

    return inCheck === 'Client' ? <Outlet /> : <Navigate to="/login" />;
};

const AdminProtectedRoute = () => {
    const inCheck = isAuth();

    return inCheck === 'Admin' ? <Outlet /> : <Navigate to="/admin/login" />;
};

export { StaffProtectedRoute, ClientProtectedRoute, AdminProtectedRoute };
