import dashboard from './menu-list/dashboard';
import companyTask from './menu-list/companyTask';
import billing from './menu-list/billing';
import messages from './menu-list/messages';
import entities from './menu-list/entities';
import workflow from './menu-list/workflow';
import requests from './menu-list/requestes';
import login from './menu-list/login';
import log from './menu-list/logs';
import compliance from './menu-list/compliance';
import adHocTask from './menu-list/adhocTask';
import todoTask from "./menu-list/todoTask";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, companyTask, adHocTask, todoTask, entities, compliance, workflow, billing, requests, messages, log, login]
};

export default menuItems;
