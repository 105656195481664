// constant
import Dashboard_icon from 'assets/images/workflow.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const workflow = {
    id: 'staff_workflow',
    type: 'group',
    children: [
        {
            id: 'staff_workflow',
            title: 'Work Flows',
            type: 'item',
            url: '/staff/workflow',
            icon: Dashboard_icon,
            breadcrumbs: false
        }
    ]
};

export default workflow;
