import React from 'react';

// material-ui
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'red',
        color: 'red',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    },
    '& .MuiAvatar-rounded': {
        backgroundColor: '#fedada'
    }
}));


const Notification = ({
	theme,
	notification,
	handleToggle,
	handleClose,
	anchorRef,
	open,
	matchesXs,
	handleread,
}) => {
    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    {notification && notification?.filter(x => x.isRead === false)?.length !== 0 ? (
                        <>
                            {' '}
                            <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant="dot">
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.mediumAvatar,
                                        transition: 'all .2s ease-in-out',
                                        background: theme.palette.primary.light,
                                        color: theme.palette.primary.dark,
                                        '&[aria-controls="menu-list-grow"],&:hover': {
                                            background: theme.palette.primary.dark,
                                            color: theme.palette.primary.light
                                        }
                                    }}
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    color="inherit"
                                >
                                    <IconBell stroke={1.5} size="1.3rem" />
                                </Avatar>
                            </StyledBadge>
                        </>
                    ) : (
                        <>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: theme.palette.primary.light,
                                    color: theme.palette.primary.dark,
                                    '&[aria-controls="menu-list-grow"],&:hover': {
                                        background: theme.palette.primary.dark,
                                        color: theme.palette.primary.light
                                    }
                                }}
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                color="inherit"
                            >
                                <IconBell stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </>
                    )}
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom-end' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">All Notification</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <button
                                                        onClick={handleread}
                                                        style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                                                    >
                                                        <Typography variant="subtitle2" color="primary" style={{cursor: "pointer"}}>
                                                            Mark as all read
                                                        </Typography>
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <PerfectScrollbar
                                                style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                            > */}
                                                <NotificationList data={notification} />
                                            {/* </PerfectScrollbar> */}
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default Notification;
