import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import io from 'socket.io-client';

import Notification from "components/notification";
import { getStorageUser } from "utils/common";

let socket;

const NotificationSection = () => {
    const theme = useTheme();
    const [notification, setnotification] = useState();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const currentuser = getStorageUser();
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        socket = io(process.env.REACT_APP_SOCKET);

        socket.on('connnection', () => {
            console.log('connected to server');
        });
      
        socket.emit('getnotification', [currentuser._id, currentuser.usertype], async (res) => {
            const result = await res;
            setnotification(result);
        });
    }, []);

    const handleread = () => {
        socket.emit('allread', [currentuser._id, currentuser.usertype], async (res) => {
            setnotification(
                notification.map((notif) => {
                    return {
                        ...notif,
                        isRead: true
                    }
                })
            );
            handleToggle();
        });
    };

    return (
        <Notification 
            theme={theme}
            notification={notification}
            handleToggle={handleToggle}
            handleClose={handleClose}
            anchorRef={anchorRef}
            open={open}
            matchesXs={matchesXs}
            handleread={handleread}
        />
    );
};

export default NotificationSection;
