import documentSvg from 'assets/images/document.svg';

const compliance = {
    id: 'compliance',
    type: 'group',
    children: [
        {
            id: 'compliance',
            title: 'Compliance',
            type: 'item',
            icon: documentSvg,
            url: 'staff/compliance'
        }
    ]
};

export default compliance;
