import todoSVG from 'assets/images/todo.svg';

const todoTask = {
    id: 'staff_todotask',
    type: 'group',
    children: [
        {
            id: 'staff_todotask_index',
            title: 'To Do Task',
            type: 'item',
            icon: todoSVG,
            url: 'staff/todo_tasks'
        }
    ]
};

export default todoTask;
