import profile from 'assets/images/Profile.svg';

const login = {
    id: 'login',
    type: 'group',
    children: [
        {
            id: 'login',
            title: 'Logout',
            type: 'item',
            icon: profile,
            url: '/logout',
            breadcrumbs: false
        }
    ]
};

export default login;
