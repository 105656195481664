import dashboard from './menu-list/dashboard';
import companyInformation from './menu-list/companyInformation';
import document from './menu-list/document';
import task_requests from './menu-list/task_requests';
import billing from './menu-list/billing';
import messages from './menu-list/messages';
import logs from './menu-list/logs';
import login from './menu-list/login';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, companyInformation, document, task_requests, billing, messages, login]
};

export default menuItems;
