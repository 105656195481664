// constant
import template_icon from 'assets/images/template.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const template = {
    id: 'admin_template',
    type: 'group',
    children: [
        {
            id: 'admin_template',
            title: 'Template',
            type: 'item',
            url: '/admin/template',
            icon: template_icon,
            breadcrumbs: false
        }
    ]
};

export default template;
