import Log_icon from 'assets/images/Group_3215.svg';

const signingLogs = {
    id: 'signingLogs',
    type: 'group',
    children: [
        {
            id: 'signingLogs',
            title: 'Signing Logs',
            type: 'item',
            icon: Log_icon,
            url: '/admin/signinglogs'
        }
    ]
};

export default signingLogs;
