// constant
import user_icon from 'assets/images/Profile.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const user = {
    id: 'admin_user',
    type: 'group',
    children: [
        {
            id: 'admin_user',
            title: 'User',
            type: 'item',
            url: '/admin/user',
            icon: user_icon,
            breadcrumbs: false
        }
    ]
};

export default user;
